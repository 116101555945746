import {CardElencoDPC, FormFiltriDPC, PageContainerDPC} from '@dpc-fe/shared';
import React from 'react';
import {graphql} from "gatsby";
import moment from "moment";
import 'moment/locale/it'
import DpcPaginator from "@dpc-fe/shared/components/dpc-paginator";
import {withPrefix} from "gatsby";
import {prop, getSlug, getCategoriaPrimariaEN} from "../functions/functions";
import {getAbstract} from "@dpc-fe/shared/js/shared-functions";

class RisultatiRicerca extends React.Component {

    constructor(props) {
        super(props);
        this.itemsPerPage = 5;
        this.state = {
            intervalloInizio: 0,
            intervalloFine: 0,
            ris: {},
            load: false,
            queryText: null,
            currentPage: 1,
            total: 0
        };
        this.data = [];
        this.fullData = [];
        this.setPage = this.setPage.bind(this);
        this.filtra = this.filtra.bind(this);
    }

    componentDidMount() {

        if (typeof window !== `undefined`)
            window.addEventListener('scroll', this.handleScroll);

        this.updateExternalJS();

        /*if (typeof document !== 'undefined' && !document.referrer || new URL(document.referrer).pathname !== `it/risultati-ricerca`) {
            this.updateExternalJS();
        }*/

        let queryText = this.props.location?.state?.search;
        if (typeof window !== 'undefined' && (!queryText && sessionStorage.getItem('keyRicercaPortaleEnPage'))) {
            queryText = sessionStorage.getItem('keyRicercaPortaleEnPage')
        }
        this.executeSearch(queryText)
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.location?.state?.search && this.props.location.state.search !== this.state.queryText) {
            this.updateExternalJS();
            const queryText = this.props.location?.state?.search;
            this.executeSearch(queryText);
        } else if ((typeof window !== 'undefined' && sessionStorage.getItem('keyRicercaPortaleItPage')) && sessionStorage.getItem('keyRicercaPortaleItPage') !== this.state.queryText) {
            this.updateExternalJS();
            const queryText = sessionStorage.getItem('keyRicercaPortaleItPage');
            this.executeSearch(queryText);
        }
    }

    componentWillUnmount() {
        if (typeof window !== `undefined`)
            window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        const clonedElements = document.querySelectorAll('.cloned');
        let clonedElementsArray = Array.prototype.slice.call(clonedElements);

        let brandWrapperArray = clonedElementsArray.filter(el => el.classList.value === 'it-brand-wrapper cloned');
        if (brandWrapperArray.length > 1) {
            brandWrapperArray[0].remove(brandWrapperArray[0]);
        }

        let searchWrapperArray = clonedElementsArray.filter(el => el.classList.value === 'it-search-wrapper cloned');
        if (searchWrapperArray.length > 1) {
            searchWrapperArray[0].remove(searchWrapperArray[0]);
        }
    }

    async executeSearch(queryText) {
        if (queryText) {
            let response = await fetch(process.env.ENDPOINT_SEARCH, {
                method: 'POST',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'testo': queryText,
                    'lingua': 'en'
                })
            });

            if (response.ok) {
                let json = await response.json();
                this.fullData = json.contents;
                this.data = [...this.fullData];
                sessionStorage.setItem('keyRicercaPortaleEnPage', queryText);
                this.setState({
                    ris: json,
                    total: json.total,
                    intervalloFine: Math.min(this.itemsPerPage, json.contents.length),
                    load: true,
                    queryText
                });
            } else {
                this.setState({
                    load: true,
                    queryText
                });
            }
        } else {
            this.setState({
                load: true,
                queryText
            });
        }

    }

    updateExternalJS() {
        ['jquery.min.js', 'popper.min.js', 'bootstrap-italia.min.js', 'main.js'].forEach(item => {
            if (!document.getElementById(item.split(".")[0] + '-js')) {
                const script = document.createElement("script");
                script.src = withPrefix(item);
                script.async = false;
                script.id = item.split(".")[0] + '-js';
                document.body.appendChild(script);
            }
        });
    }

    setPage(page) {
        let intervalloInizio = (this.itemsPerPage * page) - this.itemsPerPage;
        let intervalloFine = Math.min((intervalloInizio + this.itemsPerPage), this.state.ris.contents.length);
        this.setState({
            intervalloInizio,
            intervalloFine,
        })
    }

    filtra(filters) {
        moment.locale('it');
        this.data = this.fullData.filter(item => {
            if (filters.searchDateInit && moment(filters.searchDateInit, "DD/MM/YYYY").isAfter(moment(item.data), "day"))
                return false;
            if (filters.searchDateFine && moment(filters.searchDateFine, "DD/MM/YYYY").isBefore(moment(item.data), "day"))
                return false;
            if (filters.searchSottodomini.length)
                if (!filters.searchSottodomini.includes(item.sottodominio.toLowerCase().replace(/ /g, "_")))
                    return false;
            if (filters.searchCtgr.length)
                if (!filters.searchCtgr.includes(item.categoria.toLowerCase().replace(/ /g, "_")))
                    return false;
            //A questo punto ha superato tutti i filtri e verrà compreso nei risultati
            return true;
        });
        if (filters.searchDateInit || filters.searchDateFine || filters.searchSottodomini.length || filters.searchCtgr.length) {
            this.data.sort((a, b) => {
                return moment(a.data).isBefore(moment(b.data), "minute") ? 1 : -1;
            });
        }
        this.setState({
            total: this.data.length ? this.data.length : 0
        });
        this.setPage(1);
    }

    render() {
        if (this.state.load) {
            return (
                <PageContainerDPC basePathNavbar={process.env.PORTALE_URL}
                                  classeAggiuntivaSubnavbar={"has-header-subnavbar"}
                                  menuItems={this.props.data.menu.edges}
                                  slugTraduzione={`risultati-ricerca`}
                                  title={'Risultati ricerca'}
                                  env={process.env.ENV}
                                  lang={'en'}
                                  minisiti={prop}
                                  menuFooterItems={this.props.data.menuFooter.edges}
                >
                    <section className="container article-card-simple">
                        <h1>Search result page - "{this.state.queryText}"</h1>
                        <h6 className={"container px-custom"}>Totale: {this.state.total} risultati</h6>
                        {this.state.ris &&
                        <FormFiltriDPC lang={'en'}
                                       filtra={this.filtra}
                                       categorie={this.props.data.categorie.edges.map(item => item.node.name)}
                                       sottodomini={this.props.data.sottodomini.edges.map(item => item.node.name).filter(item => item.toLowerCase() !== 'portale')}
                                       fullText={true}
                                       text={this.state.queryText}
                        />
                        }


                        <div className="px-custom">
                            {(this.data && this.data.length) ?
                                this.data.slice(this.state.intervalloInizio, this.state.intervalloFine).map(item => {
                                    return (
                                        <CardElencoDPC
                                            title={item.titolo}
                                            body={getAbstract(item.abstract, 150)}
                                            slug={getSlug(item.urlContenuto, process.env.PORTALE, item.sottodominio)}
                                            categoria={item.categoria.replace(/_/g, ' ')}
                                            data_creazione={moment(item.data).format("LL")}
                                            dataFormat={true}
                                        />)
                                }) : <section className="container article-card-simple">
                                    <div className="px-custom">
                                        <div className="text-center py-5">
                                            <h1>La ricerca non ha prodotto risultati</h1>
                                        </div>
                                    </div>
                                </section>}
                            <DpcPaginator setPage={this.setPage} numItems={this.data?.length}
                                          itemsPerPage={this.itemsPerPage} key={this.data?.length}
                                          currentPage={this.state.currentPage}/>
                        </div>
                    </section>
                </PageContainerDPC>
            );
        } else return (<></>);

    }
}

export default RisultatiRicerca;

export const query = graphql`
    {
         menu: allTaxonomyTermMenuPortaleEn {
          edges {
            node {
              name
              weight
              relationships {
                field_link_menu {
                    ... on node__approfondimento {
                        fields {
                          slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__notizia {
                        fields {
                          slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__page {
                        fields {
                          slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__sezione_senza_anticipazioni {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__sezione_con_anticipazioni {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_approfondimenti {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_podcast {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_notizie_comunicati_stampa {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_glossario {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_pubblicazioni {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_normative {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_bandi_di_gara_e_contratti {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_foto_e_video {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__homepage_minisito_volontariato {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                        drupal_internal__nid
                    }
                    ... on node__homepage_minisito_rischi {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                        drupal_internal__nid
                    }
                    ... on node__homepage_minisito_ser_nazionale {
                        fields {
                                slug
                           }
                           relationships {
                                field_sottodominio {
                                    name
                                }
                           }
                           drupal_internal__nid
                    }
                    ... on node__homepage_minisito_relazioni {
                        fields {
                                slug
                           }
                           relationships {
                                field_sottodominio {
                                    name
                                }
                           }
                           drupal_internal__nid
                    }
                    ... on node__homepage_minisito_mappe {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_scuola_giovani {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_formazione {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_eventi {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_emergenze {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_domande {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_docs {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_pnrr {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_it_alert {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                }
                parent {
                  name
                  drupal_internal__tid
                }
              }
              drupal_internal__tid
            }
          }
        },
        categorie: allTaxonomyTermTipiContenutoRicerca {
          edges {
            node {
              name
            }
          }
        },
        sottodomini: allTaxonomyTermSottodomini(filter: {name: {nin: ["Io Non Rischio","IT Alert"]}}) {
          edges {
            node {
              name
            }
          }
        },
        menuFooter: allTaxonomyTermMenuFooterEn {
          edges {
            node {
              name
              weight
              relationships {
                field_link_menu {
                    ... on node__approfondimento {
                        fields {
                          slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__page {
                        fields {
                          slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_glossario {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                }
              }
              drupal_internal__tid
            }
          }
        }
    }
    `;
